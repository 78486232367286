import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {UserProfileQueryResponse} from "./services/userProfile";


export interface UserState {
  user: null | UserProfileQueryResponse;
}

const initialState: UserState = {
  user: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserProfileQueryResponse>) => {
      state.user = action.payload;
    },
  },
});

export const {
  setUser,
} = userSlice.actions;

export default userSlice.reducer;
